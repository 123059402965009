import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ImageCard>`}</inlineCode>{` component should generally be used inside of a `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` component.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Title" subTitle="Subtitle" href="/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMFAQIE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAa3EcdSaprtrxAH/xAAbEAEBAAEFAAAAAAAAAAAAAAABAiEAEBESMf/aAAgBAQABBQLtrFtSDHk1w1Spi9v/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAwEBPwEj/8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQMf/aAAgBAgEBPwEtG2f/xAAaEAACAgMAAAAAAAAAAAAAAAAAARARICIx/9oACAEBAAY/Ars2R3BT/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERMSFBEGFxsf/aAAgBAQABPyG+joxGj0a18iSyUS9OhuUlclPTY8vj/9oADAMBAAIAAwAAABCfz0D/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAEDAQE/EAVtQxf/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAECAQE/EASRar//xAAdEAEAAwEAAgMAAAAAAAAAAAABABEhMRBRQWGx/9oACAEBAAE/EBCGF4pYAFfR+PUpGAWtOXBOJCk1vIqa9ty6Yya9EqEnQPOL+kVi9d8f/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/2c28416b40d3a9b2069c2c5789bba21d/16532/square.jpg",
              "srcSet": ["/static/2c28416b40d3a9b2069c2c5789bba21d/69549/square.jpg 288w", "/static/2c28416b40d3a9b2069c2c5789bba21d/473e3/square.jpg 576w", "/static/2c28416b40d3a9b2069c2c5789bba21d/16532/square.jpg 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
  <ImageCard title="Dark title" titleColor="dark" aspectRatio="1:1" href="/" actionIcon="arrowRight" subTitleColor="light" subTitle="Light subtitle" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAwb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAF3COscmdD/xAAZEAEBAQADAAAAAAAAAAAAAAAAAQMCEhP/2gAIAQEAAQUCVa9M456yu7//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAkRASIUFC/9oACAEBAAY/Ap6ZizNs/8QAHBAAAgEFAQAAAAAAAAAAAAAAAAEREDFRcZHR/9oACAEBAAE/IaEJkzXM9Yjk79D/2gAMAwEAAgADAAAAEFPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQEAAwEAAAAAAAAAAAAAAREAIUGRwf/aAAgBAQABPxAOZ3LNAc5SOFUJ4AX9uHBGHafdYfXH/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Light dark",
              "title": "Light dark",
              "src": "/static/9bc0205f44d1ede2fb1b009502412154/2e753/light-dark.jpg",
              "srcSet": ["/static/9bc0205f44d1ede2fb1b009502412154/69549/light-dark.jpg 288w", "/static/9bc0205f44d1ede2fb1b009502412154/473e3/light-dark.jpg 576w", "/static/9bc0205f44d1ede2fb1b009502412154/2e753/light-dark.jpg 1152w", "/static/9bc0205f44d1ede2fb1b009502412154/74f4b/light-dark.jpg 1728w", "/static/9bc0205f44d1ede2fb1b009502412154/de5bb/light-dark.jpg 2304w", "/static/9bc0205f44d1ede2fb1b009502412154/72f4e/light-dark.jpg 4506w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Title" subTitle="Subtitle" aspectRatio="1:2" href="/" actionIcon="download" titleColor="light" subTitleColor="light" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFBAH/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQD/2gAMAwEAAhADEAAAAZTXEigGo0uZZPS2M4h//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAAwQREBMi/9oACAEBAAEFAlRnjVuvFSAVOpWZAHYxXS5PrIO7hYQA+jP/xAAXEQEBAQEAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/AcWOVX//xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPwG40R//xAAdEAABBQADAQAAAAAAAAAAAAABAAIQESESIjFB/9oACAEBAAY/Auota2Gt+1cZmIEejylxcE6OQGx//8QAHBABAAICAwEAAAAAAAAAAAAAAQARITEQUWGR/9oACAEBAAE/IWKSNocDzuQDAsB2Ap7G3AWW0uLbWrMSz+1KIrPrqxbbds//2gAMAwEAAgADAAAAEDfTjf/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEUH/2gAIAQMBAT8Q3ywcmlqt/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAQf/aAAgBAgEBPxCO4aXEGEJv/8QAHhABAAICAgMBAAAAAAAAAAAAAQARITFBYRBRgaH/2gAIAQEAAT8QQUTaaPsBUocmT88MCCI3TnmXlB4C4PRao9Lcw9HFdK1XJ1L4xAFl7IBDgq+YhhgMCMEwJbNY+69xGS0te5//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Plane image",
              "title": "Plane image",
              "src": "/static/0932e6c8832649df0753e2b655ebb6be/2e753/plane.jpg",
              "srcSet": ["/static/0932e6c8832649df0753e2b655ebb6be/69549/plane.jpg 288w", "/static/0932e6c8832649df0753e2b655ebb6be/473e3/plane.jpg 576w", "/static/0932e6c8832649df0753e2b655ebb6be/2e753/plane.jpg 1152w", "/static/0932e6c8832649df0753e2b655ebb6be/74f4b/plane.jpg 1728w", "/static/0932e6c8832649df0753e2b655ebb6be/de5bb/plane.jpg 2304w", "/static/0932e6c8832649df0753e2b655ebb6be/b8d8d/plane.jpg 3744w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard aspectRatio="1:1" href="/" hoverColor="dark" mdxType="ImageCard">
          <img {...{
            "src": "/e357792c0f36b2e282a28e5da508b1a0/color-grid.svg",
            "alt": "color pallete array"
          }}></img>
  </ImageCard>
      <ImageCard disabled aspectRatio="1:1" href="/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMFAQIE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAa3EcdSaprtrxAH/xAAbEAEBAAEFAAAAAAAAAAAAAAABAiEAEBESMf/aAAgBAQABBQLtrFtSDHk1w1Spi9v/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAwEBPwEj/8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQMf/aAAgBAgEBPwEtG2f/xAAaEAACAgMAAAAAAAAAAAAAAAAAARARICIx/9oACAEBAAY/Ars2R3BT/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERMSFBEGFxsf/aAAgBAQABPyG+joxGj0a18iSyUS9OhuUlclPTY8vj/9oADAMBAAIAAwAAABCfz0D/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAEDAQE/EAVtQxf/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAECAQE/EASRar//xAAdEAEAAwEAAgMAAAAAAAAAAAABABEhMRBRQWGx/9oACAEBAAE/EBCGF4pYAFfR+PUpGAWtOXBOJCk1vIqa9ty6Yya9EqEnQPOL+kVi9d8f/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/2c28416b40d3a9b2069c2c5789bba21d/16532/square.jpg",
              "srcSet": ["/static/2c28416b40d3a9b2069c2c5789bba21d/69549/square.jpg 288w", "/static/2c28416b40d3a9b2069c2c5789bba21d/473e3/square.jpg 576w", "/static/2c28416b40d3a9b2069c2c5789bba21d/16532/square.jpg 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/ImageCard/ImageCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ImageCard",
        "path": "components/ImageCard/ImageCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ImageCard"
      }}>{`<Row className="image-card-group">
  <Column colMd={4} colLg={4} noGutterSm>
    <ImageCard title="Title" subTitle="Subtitle" href="/">
      ![Square](/images/square.jpg)
    </ImageCard>
    <ImageCard
      title="Dark title"
      titleColor="dark"
      aspectRatio="1:1"
      href="/"
      actionIcon="arrowRight"
      subTitleColor="light"
      subTitle="Light subtitle"
    >
      ![Light dark](./images/light-dark.jpg)
    </ImageCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    <ImageCard
      title="Title"
      subTitle="Subtitle"
      aspectRatio="1:2"
      href="/"
      actionIcon="download"
      titleColor="light"
      subTitleColor="light"
    >
      ![Plane image](./images/plane.jpg)
    </ImageCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    <ImageCard aspectRatio="1:1" href="/" hoverColor="dark">
      ![color pallete array](/images/color-grid.svg)
    </ImageCard>
    <ImageCard disabled aspectRatio="1:1" href="/">
      ![Square](/images/square.jpg)
    </ImageCard>
  </Column>
</Row>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background image for the card, make sure it is saved out at the correct aspect ratio or it will appear distorted`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aspectRatio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1:1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set card aspect ratio, default is `}<inlineCode parentName="td">{`1:1`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`1:1`}</inlineCode>{`, `}<inlineCode parentName="td">{`16:9`}</inlineCode>{`, `}<inlineCode parentName="td">{`4:3`}</inlineCode>{`, `}<inlineCode parentName="td">{`2:1`}</inlineCode>{`, `}<inlineCode parentName="td">{`1:2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Smaller title in bottom left of card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Large title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is no icon, options are `}<inlineCode parentName="td">{`Launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`ArrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`Download`}</inlineCode>{`, `}<inlineCode parentName="td">{`Disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`Email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set title text color, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` or `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitleColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set sub title text color, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` or `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set icon color, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` or `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hoverColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set hover to lighten or darken the image, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` or `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      